<template>
  <div>
    <div class="main-header">
      <div class="title">商品管理</div>
    </div>
    <div class="main-main">
      <a-card>
        <div class="search-card">
          <div class="search-row">
            <div class="search-option">
              <span class="label">商品名称</span>
              <a-input
                placeholder="请输入商品名称"
                v-model="searchGoodsName"
              ></a-input>
            </div>
            <div class="search-option">
              <span class="label">所属组织</span>
              <a-tree-select
                class="search-select"
                allowClear
                v-model="searchOptions.groupId"
                :tree-data="groupTree"
                placeholder="请选择所属组织"
              >
              </a-tree-select>
            </div>
            <div class="empty">
              <a-button type="primary" @click="searchByOptions" icon="search"
                >搜索</a-button
              >
            </div>
            <div class="empty"></div>
            <div class="empty"></div>
          </div>
        </div>
        <div class="optionsAndPagination"></div>
        <div class="actions">
          <div class="btns" style="gap: 10px">
            <a-button icon="plus-circle" @click="addGoods">新增</a-button>
            <a-button icon="minus-circle" @click="delGoods">删除</a-button>
          </div>
          <Pagination
            @refresh="refresh"
            @pageChange="pageChange"
            @pageSizeChange="pageSizeChange"
            :total="total"
            :page="searchOptions.page"
          ></Pagination>
        </div>
        <div>
          <a-table
            size="middle"
            :loading="tableLoading"
            :data-source="tableData"
            :columns="tableColumns"
            :pagination="false"
            :row-selection="{
              selectedRowKeys: selectedRowKeys,
              onChange: onSelectChange,
            }"
            :scroll="{ x: 10 }"
          >
            <template slot="createTime" slot-scope="createTime">
              {{ $dayjs(createTime).format("YYYY-MM-DD HH:mm:ss") }}
            </template>
            <template slot="goodsStatus" slot-scope="goodsStatus">
              {{ goodsStatus == 0 ? "上线" : "下线" }}
            </template>

            <template slot="action" slot-scope="row">
              <div style="display: flex; gap: 10px; justify-content: center">
                <a @click="editGood(row)">编辑</a>
                <a @click="showExchange(row)">兑换列表</a>
                <a @click="changeGoodStatus(row)">{{
                  row.goodsStatus == 0 ? "下线" : "上线"
                }}</a>
                <a @click="delGood([row.id])">删除</a>
              </div>
            </template>
          </a-table>
        </div>
      </a-card>
    </div>
    <a-modal
      :footer="null"
      :closable="false"
      :title="addOrEditGoodsTitle"
      v-model="addGoodModal"
      :width="900"
    >
      <a-form-model
        :model="form"
        :rules="rules"
        class="activity-form"
        :labelCol="{ span: 6 }"
        :wrapperCol="{ span: 14 }"
        ref="form"
      >
        <a-form-model-item label="商品名称" prop="goodsName">
          <a-input v-model="form.goodsName"></a-input>
        </a-form-model-item>
        <a-form-model-item label="商品类型" prop="goodsType">
          <a-select :options="allGoodsType" v-model="form.goodsType"></a-select>
        </a-form-model-item>
        <a-form-model-item label="所需时间币" prop="timeMoney">
          <a-input type="number" v-model="form.timeMoney"></a-input>
        </a-form-model-item>
        <a-form-model-item label="库存数量" prop="stock">
          <a-input type="number" v-model="form.stock"></a-input>
        </a-form-model-item>
        <a-form-model-item label="商品介绍" prop="goodsIntroduce">
          <a-input v-model="form.goodsIntroduce"></a-input>
        </a-form-model-item>
        <a-form-model-item label="商品图片上传" prop="imageUrl">
          <a-upload
            name="file"
            list-type="picture-card"
            class="avatar-uploader"
            :show-upload-list="false"
            action="https://upload-z0.qiniup.com"
            :data="{ token: uploadToken, key: uploadKey }"
            :before-upload="beforeUpload"
            @change="handleChange"
          >
            <img
              v-if="form.imageUrl"
              :src="form.imageUrl"
              alt="avatar"
              style="width: 100px; width: 100px"
            />
            <div v-else>
              <a-icon :type="uploading ? 'loading' : 'plus'" />
              <div class="ant-upload-text">请选择文件</div>
            </div>
          </a-upload>
        </a-form-model-item>
        <a-form-model-item label=""> </a-form-model-item>
      </a-form-model>
      <div style="text-align: center">
        <a-button type="primary" @click="submit" :disabled="submitDisable"
          >提交保存</a-button
        >
      </div>
    </a-modal>
    <a-modal
      :footer="null"
      :closable="false"
      v-model="exchangeListModal"
      :title="exchangeExcelTitle"
      :width="800"
    >
      <a-table
        :data-source="exchangeList"
        :columns="exchangeListTableColumns"
        :pagination="false"
        size="small"
        :scroll="{
          y: 350,
        }"
      >
        <template slot="exTime" slot-scope="exTime">
          {{ exTime }}
        </template>
        <template slot="status" slot-scope="status">
          {{ status == 0 ? "未核销" : "已核销" }}
        </template>
      </a-table>
      <div style="text-align: center; margin-top: 20px">
        <a-button icon="plus" style="margin-right: 20px" @click="addPeople"
          >补录</a-button
        >
        <a-button icon="download" type="primary"
          >导出保存<vue-excel-xlsx
            class="excel-btn"
            :columns="exchangeListExcelColumns"
            :sheetname="exchangeExcelTitle"
            :filename="exchangeExcelTitle"
            :data="exchangeList"
          >
          </vue-excel-xlsx
        ></a-button>
      </div>
    </a-modal>
    <a-modal
      title="补录"
      :footer="null"
      :closable="false"
      v-model="addPeopleModal"
    >
      <div class="search-row">
        <a-input
          placeholder="请输用户名"
          v-model="peopleSearchKeyword"
        ></a-input>
        <a-button type="primary" @click="searchPeople">查询</a-button>
        <a-button @click="resetPeopleSearch">重置</a-button>
      </div>
      <div class="search-result-container" v-if="peopleSearchResult.length">
        <div
          v-for="(item, index) in peopleSearchResult"
          :key="index"
          class="search-result"
        >
          <span>{{ item.nickName }}</span>
          <div class="search-result-btn">
            <a-button
              type="primary"
              @click="addThisPeople(item.id)"
              size="small"
              >补录</a-button
            >
          </div>
        </div>
      </div>
      <div v-else style="margin: 20px 0">
        <a-empty />
      </div>
    </a-modal>
  </div>
</template>

<script>
import {
  addGood,
  changeGood,
  changeGoodStatus,
  delGood,
  getGoodsList,
  getGoodsExchangeList,
  getSevenNiuToken,
  getOptions,
  goodsAddPeople,
  getUserList
} from "@/network/api";
import Pagination from "../components/Pagination";
import { mapState } from "vuex";
export default {
  components: {
    Pagination,
  },
  data() {
    return {
      searchGoodsName: "",
      submitDisable: false,
      exchangeExcelTitle: "",
      exchangeList: [],
      exchangeListModal: false,
      allGoodsType: [],
      form: {},
      addOrEditGoodsTitle: "新增商品",
      addGoodModal: false,
      total: 0,
      selectedRowList: [],

      selectedRowKeys: [],
      tableLoading: true,
      tableData: [],
      searchOptions: {
        page: 1,
        pageSize: 20,
      },
      allUserType: [
        { title: "长者", value: 1 },
        { title: "志愿者", value: 2 },
        { title: "管理员", value: 3 },
      ],
      uploadToken: "",
      uploadKey: "",
      uploadUrl: "",
      uploading: false,

      // 补录
      addPeopleModal: false,
      peopleSearchKeyword: "",
      peopleSearchResult: [],
      currentGoodId: '', // 当前商品id
    };
  },
  methods: {
    getUploadToken() {
      return new Promise((resolve, reject) => {
        getSevenNiuToken("https://m2.zyh365.com/").then((res) => {
          this.uploadToken = res.data.token;
          this.uploadKey = res.data.key;
          this.uploadUrl = res.data.url;
          resolve();
        });
      });
    },
    handleChange(info) {
      if (info.file.status === "uploading") {
        this.uploading = true;
        return;
      }
      if (info.file.status === "done") {
        this.$set(
          this.form,
          "imageUrl",
          this.uploadUrl + info.file.response.key
        );
        console.log(this.form);
        this.getUploadToken();
      }
    },
    beforeUpload(file) {
      const isJpgOrPng =
        file.type === "image/jpeg" || file.type === "image/png";
      if (!isJpgOrPng) {
        this.$message.error("请上传jpg或png格式图片!");
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$message.error("图片不能大于2MB!");
      }
      return isJpgOrPng && isLt2M && this.getUploadToken();
    },
    delGood(ids) {
      if (!ids.length) {
        return this.$message.error("请勾选要删除的商品！");
      }
      this.$confirm({
        title:
          ids.length > 1 ? "确定要删除这些商品吗？" : "确定要删除该商品吗？",
        onOk: () => {
          return new Promise((resolve, reject) => {
            delGood({ ids: ids }).then((res) => {
              if (res.status == 0) {
                this.getGoodsList();
                this.$message.success("删除成功！");
                resolve();
              } else {
                reject();
              }
            });
          }).catch(() => {
            this.$message.error("删除失败！");
          });
        },
      });
    },
    changeGoodStatus(row) {
      var params = {
        id: row.id,
      };
      var text = "";
      if (row.goodsStatus == 1) {
        params.goodsStatus = 0;
        text = "上线";
      } else {
        params.goodsStatus = 1;
        text = "下线";
      }
      this.$confirm({
        title: `确定要${text}该商品吗？`,
        onOk: () => {
          return new Promise((resolve, reject) => {
            changeGoodStatus(params).then((res) => {
              if (res.status == 0) {
                this.getGoodsList();
                this.$message.success(`${text}成功！`);
                resolve();
              } else {
                reject();
              }
            });
          }).catch(() => {
            this.$message.error(`${text}失败！`);
          });
        },
      });
    },
    async getGoodsList(
      params = {
        page: 1,
        pageSize: 20,
      }
    ) {
      this.selectedRowList = [];
      this.selectedRowKeys = [];
      this.tableLoading = true;
      const { data } = await getGoodsList(params);
      this.total = data.total;
      this.tableData = data.content.map((item) => {
        return item;
      });
      this.tableLoading = false;
    },
    async getGoodsExchangeList(params) {
      const { data } = await getGoodsExchangeList(params);
      this.exchangeList = data;
    },
    showExchange(row) {
      this.exchangeListModal = true;
      this.exchangeExcelTitle = row.goodsName + "兑换列表";
      console.log(row);
      const params = {
        productId: row.id,
      };
      this.currentGoodId = row.id;
      this.getGoodsExchangeList(params);
    },
    submit() {
      this.$refs.form.validate((res) => {
        if (res) {
          this.submitDisable = true;
          if (this.addOrEditGoodsTitle == "编辑商品") {
            this.form.pics = [this.form.imageUrl];
            changeGood(this.form).then((res) => {
              if (res.status == 0) {
                this.addGoodModal = false;
                this.$message.success("编辑成功！");
                this.getGoodsList(this.searchOptions);
              } else {
                this.submitDisable = false;
                this.$message.error("编辑失败！");
              }
            });
          } else {
            this.form.pics = [this.form.imageUrl];
            addGood(this.form).then((res) => {
              if (res.status == 0) {
                this.addGoodModal = false;
                this.$message.success("新增成功！");
                this.getGoodsList();
              } else {
                this.submitDisable = false;
                this.$message.error("新增失败！");
              }
            });
          }
        }
      });
    },
    editGood(row) {
      this.form = {
        ...row,
      };
      this.uploading = false;
      this.$set(this.form, "imageUrl", row.pics ? row.pics[0] : "");
      this.addOrEditGoodsTitle = "编辑商品";
      this.addGoodModal = true;
      this.submitDisable = false;
      this.$nextTick(() => {
        this.$refs.form.clearValidate();
      });
    },
    addGoods() {
      this.form = {};
      this.addOrEditGoodsTitle = "新增商品";
      this.addGoodModal = true;
      this.submitDisable = false;
      this.$nextTick(() => {
        this.$refs.form.clearValidate();
      });
    },
    delGoods() {
      const ids = this.selectedRowList;
      this.delGood(ids);
    },

    // 补录
    addPeople() {
      this.addPeopleModal = true;
    },
    searchPeople() {
      getUserList({
        nickName: this.peopleSearchKeyword,
        page: 1,
        pageSize: 999,
      }).then((res) => {
        if (res.status == 0) {
          this.peopleSearchResult = res.data.content.map((item) => {
            return item;
          });
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    resetPeopleSearch() {
      this.peopleSearchKeyword = "";
      this.peopleSearchResult = [];
    },
    addThisPeople(userId) {
      goodsAddPeople({
        goodsId: this.currentGoodId,
        userId,
      }).then((res) => {
        if (res.status == 0) {
          this.$message.success("补录成功！");
          this.addPeopleModal = false;
          this.getGoodsExchangeList({
            productId: this.currentGoodId
          });
          this.refresh();
        } else {
          this.$message.error(res.msg);
        }
      });
    },

    pageChange(page, pageSize) {
      this.searchOptions.page = page;
      this.getGoodsList(this.searchOptions);
    },
    pageSizeChange(page, pageSize) {
      this.searchOptions.page = 1;
      this.searchOptions.pageSize = pageSize;
      this.getGoodsList(this.searchOptions);
    },
    refresh() {
      this.getGoodsList();
    },
    searchByOptions() {
      this.searchOptions.page = 1;
      this.searchOptions.goodsName = this.searchGoodsName;
      // this.searchOptions.pageSize = 20;
      this.getGoodsList(this.searchOptions);
    },
    onSelectChange(e) {
      this.selectedRowKeys = e;
      this.selectedRowList = [];
      this.selectedRowKeys.forEach((item) => {
        this.selectedRowList.push(this.tableData[item].id);
      });
    },
  },

  created() {
    this.getGoodsList();
    getOptions({
      categoryId: 28,
      typeId: 0,
    }).then((res) => {
      this.allGoodsType = res.data.content.map((item) => {
        item.title = item.name;
        item.value = item.id;
        return item;
      });
    });
  },
  computed: {
    ...mapState(["groupTree"]),
    exchangeListExcelColumns() {
      return [
        { label: "姓名", field: "nickName" },
        { label: "手机号", field: "phone" },
        {
          label: "兑换日期",
          field: "exTime",
          // dataFormat: (e) => this.$dayjs(e).format("YYYY-MM-DD HH:mm:ss"),
        },
        { label: "时间币", field: "timeMoney" },
        { label: "核销状态", field: "status" },
        { label: "备注", field: "remark" },
      ];
    },
    exchangeListTableColumns() {
      return [
        { dataIndex: "nickName", title: "姓名", align: "center" },
        { dataIndex: "phone", title: "手机号", align: "center" },
        {
          dataIndex: "exTime",
          title: "兑换日期",
          align: "center",
          scopedSlots: {
            customRender: "exTime",
          },
        },
        { dataIndex: "timeMoney", title: "时间币", align: "center" },
        {
          dataIndex: "status",
          title: "核销状态",
          align: "center",
          scopedSlots: {
            customRender: "status",
          },
        },
        { dataIndex: "remark", title: "备注", align: "center" },
      ];
    },
    rules() {
      return {
        imageUrl: [
          {
            required: true,
            message: "请上传商品图片",
            trigger: ["chnage"],
          },
        ],
        goodsName: [
          {
            required: true,
            message: "请输入商品名称",
            trigger: "blur",
          },
        ],
      };
    },
    tableColumns() {
      return [
        {
          dataIndex: "goodsName",
          title: "商品名",
          align: "center",
          width: 200,
        },
        {
          dataIndex: "nickName",
          title: "发布人",
          align: "center",
        },
        {
          dataIndex: "addTime",
          title: "发布时间",
          align: "center",
          scopedSlots: { customRender: "createTime" },
          width: 300,
        },
        { dataIndex: "stock", title: "库存", align: "center", width: 90 },

        {
          dataIndex: "goodsStatus",
          title: "状态",
          align: "center",
          scopedSlots: { customRender: "goodsStatus" },
        },
        {
          title: "操作",
          align: "center",
          scopedSlots: { customRender: "action" },
          width: 300,
        },
      ];
    },
  },
};
</script>

<style lang="less" scoped>
.title {
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
  font-size: 22px;
}
.date-picker {
  display: flex;
  align-items: center;
}

.ant-card-body {
  padding: 0.56rem 1.06rem;
}
.ant-tabs-bar {
  margin: 0;
}

.ant-tabs-tab {
  border-radius: none !important;
}

.ant-table-row-cell-break-word {
  text-align: center !important;
}

.pagination {
  display: flex;
}
.actions {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
.actions .label {
  display: flex;
  align-items: center;
}
.actions .btns {
  display: flex;
  justify-content: space-between;
}
.excel-btn {
  padding: 0 !important;
  border: 0 !important;
  background-color: transparent !important;
  cursor: pointer;
  vertical-align: middle;
}
.excel-btn:after {
  top: 0;
  left: 0;
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 4px;
}
.ant-tabs-tab {
  margin-right: 0;
}

.search-card {
  margin-bottom: 10px;
}

.search-row {
  display: flex;
  button {
    margin-left: 1rem;
  }
}
.search-result-container {
  margin-top: 1rem;
  max-height: 15rem;
  overflow-y: scroll;
  justify-content: center;
  border-bottom: 1px solid #eee;
  .search-result {
    height: 3rem;
    line-height: 3rem;
    text-align: center;
    border-radius: 4px;
    cursor: pointer;
    position: relative;
    border-bottom: 1px solid #eee;
    &:last-of-type {
      border-bottom: none;
    }
    .search-result-btn {
      position: absolute;
      right: 3rem;
      top: 0;
      display: none;
    }
    &:hover {
      background: #e6f7ff;
      color: #1890ff;
      .search-result-btn {
        display: block;
      }
    }
  }
}
</style>